import { CONFIG } from '../../config';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../app/core/routes';

export const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <footer>
      <a href={`mailto: ${CONFIG.supportEmail}`}
         className="contact"
         target="_blank"
         rel="noreferrer"
      >{CONFIG.supportEmail}</a>

      <div className="footer-links">
        <Link
          className={'terms-link'}
          to={ROUTES.terms}>
          Terms & Conditions
        </Link>

        <Link
          className={'privacy-link'}
          to={ROUTES.privacy}>
          Privacy Policy
        </Link>
      </div>

      <div className="copy">
        {CONFIG.companyName} © {year}
      </div>
    </footer>
  );
}
