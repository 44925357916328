import React, { useCallback, useEffect, useRef, useState } from 'react';
import { wrapPage } from '../components/wrapPage/wrapPage';
import { Helmet } from "react-helmet";

import { Navigation } from '../components/navigation';
import { NavigationItems } from '../components/navigation-items';
import { CONFIG } from '../../config';

const SWIPE_IGNORE_TIME = 1500;

const MainPage = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const incrementActiveIndex = useCallback(() => {
    if (activeIndex < 3) {
      setActiveIndex(activeIndex + 1);
    }
  }, [activeIndex]);

  const decrementActiveIndex = useCallback(() => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  }, [activeIndex]);

  const setScrollTimeout = () => {
    scrollTimeout.current = setTimeout(() => {
      scrollTimeout.current = null;
    }, SWIPE_IGNORE_TIME);
  };

  const handleScroll = useCallback((event: WheelEvent) => {
    if (scrollTimeout.current !== null) {
      return;
    }

    if (event.deltaY > 0) {
      incrementActiveIndex();
    } else if (event.deltaY < 0) {
      decrementActiveIndex();
    }

    setScrollTimeout();
  }, [decrementActiveIndex, incrementActiveIndex]);

  const touchStartY = useRef<number | null>(null);
  const touchStartX = useRef<number | null>(null);

  const handleTouchStart = useCallback((event: TouchEvent) => {
    touchStartY.current = event.touches[0].clientY;
    touchStartX.current = event.touches[0].clientX;
  }, []);

  const handleTouchMove = useCallback((event: TouchEvent) => {
    if (scrollTimeout.current !== null || touchStartY.current === null || touchStartX.current === null) {
      return;
    }

    const touchMoveY = event.changedTouches[0].clientY;
    const touchMoveX = event.changedTouches[0].clientX;

    const deltaY = Math.abs(touchMoveY - touchStartY.current);
    const deltaX = Math.abs(touchMoveX - touchStartX.current);

    if (deltaX > deltaY) {
      // The swipe was horizontal, ignore it
      return;
    }

    if (touchStartY.current > touchMoveY && activeIndex < 3) {
      incrementActiveIndex();
    } else if (touchStartY.current < touchMoveY && activeIndex > 0) {
      event.preventDefault();
      decrementActiveIndex();
    }

    touchStartY.current = null;
    touchStartX.current = null;
    setScrollTimeout();
  }, [decrementActiveIndex, incrementActiveIndex, activeIndex]);

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, {passive:false});

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [handleTouchStart, handleTouchMove, handleScroll]);

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart, {passive:false});
    window.addEventListener('touchmove', handleTouchMove, {passive:false});

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [handleTouchStart, handleTouchMove]);

  return (
    <>
      <Helmet>
        <meta name="description" content={`${CONFIG.companyName} Promo Landing`}/>
        {/*<meta name="keywords" content=""/>*/}
        <title>{CONFIG.TITLE}</title>
        {/*<link rel="canonical" href={`${process.env.REACT_APP_WEB_ROOT}`} />*/}
      </Helmet>

      <div className="content" ref={contentRef}>
        <div className="content__column logo-container">
          <div className="logo"></div>
        </div>

        <div className="content__column navigation-container">
          <NavigationItems activeIndex={activeIndex}/>
        </div>

        <div className={`scroll-down ${activeIndex > 0 ? 'hidden' : ''}`}>
          <div className="scroll-down-text">Scroll down</div>
          <div className="scroll-down-image">
            <div className="scroll-down-image__bg"></div>
            <div className="scroll-down-image__arrow"></div>
          </div>
        </div>

        <Navigation
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </div>
    </>
  );
};

export default wrapPage(MainPage);
