import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ROUTES } from './app/core/routes';
import './main.scss';
import MainPage from './app/pages/main.page';
import { createRoot } from 'react-dom/client';
import ErrorPage from './app/pages/error.page';
import TermsPage from './app/pages/rules/termsPage';
import PrivacyPage from './app/pages/rules/privacyPage';

const AppWrapper = () => {
  return (
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path='*' element={<ErrorPage wrapperClassName="error-page" />} />
          <Route path={ROUTES.main} element={<MainPage wrapperClassName="main-page" />} />
          <Route path={ROUTES.terms} element={<TermsPage wrapperClassName="rules-page" />} />
          <Route path={ROUTES.privacy} element={<PrivacyPage wrapperClassName="rules-page" />} />
        </Routes>
      </Router>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById('root');

const app = <>
  <AppWrapper />
</>;

if (rootElement?.hasChildNodes()) {
  hydrateRoot(rootElement, app);
} else if (rootElement) {
  // Client render
  createRoot(rootElement).render(app);
}

