import React, { useEffect, useRef } from 'react';
// import laptopIcon from '../../assets/img/laptop.png';
// import argoLogo from '../../assets/img/partners/argo.svg';
// import brandonLogo from '../../assets/img/partners/btv.svg';
// import supafanatixLogo from '../../assets/img/partners/supafanatix.svg';
// import aviSnowLogo from '../../assets/img/partners/avi-snow.svg';
import { CONFIG } from '../../config';


export const NavigationItems = (props: {
  activeIndex: number;
}) => {
  const { activeIndex } = props;
  const prevActiveIndex = useRef<number | null>(null);

  useEffect(() => {
    if (prevActiveIndex.current !== null) {
      prevActiveIndex.current = activeIndex;
    } else {
      // Игнорируем первое обновление
      prevActiveIndex.current = activeIndex;
    }
  }, [activeIndex]);

  const getClassName = (index: number) => {
    const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
    // @ts-ignore
    const direction = prevActiveIndex.current === null ? 'Default' : activeIndex > prevActiveIndex?.current ? 'up' : 'down';

    if (index === activeIndex) {
      return `active custom-animate__in${capitalize(direction)}`;
    } else if (index === prevActiveIndex.current) {
      return `prev custom-animate__out${capitalize(direction)}`;
    }

    return '';
  };

  return (
    <>
      <div className={`slide ${getClassName(0)}`}>
        <div className="text-block">
          <h1 className="title">
            Be independent,
            <span className="highlight-title">Build your community</span>
            <span className="highlight-title" style={{ marginTop: '12px' }}>own your success!</span>
          </h1>
        </div>
      </div>

      <div className={`slide ${getClassName(1)}`}>
        <div className="text-block extra-margin--1">
          <h2 className="title">
            One platform
            <span className="highlight-title">infinite possibilities</span>
          </h2>
          <ul className="list-items">
            <li>Talk to your fans</li>
            <li>Monetize your content</li>
            <li>Collaborate with brands and creators</li>
          </ul>
          {/*<p className="description">*/}
          {/*  Bring your brand & audience into your world.*/}
          {/*  <br/>*/}
          {/*  Join the MAJI network to seamlessly collaborate with creators, Influencers and artists*/}
          {/*  <br/>*/}
          {/*  <br/>*/}
          {/*  Comparable with all existing platforms:*/}
          {/*</p>*/}
          {/*<div className="socials">*/}
          {/*  <span className="socials__button socials__button--google">google</span>*/}
          {/*  <span className="socials__button socials__button--instagram">instagram</span>*/}
          {/*  <span className="socials__button socials__button--apple">apple</span>*/}
          {/*  <span className="socials__button socials__button--fb">facebook</span>*/}
          {/*  <span className="socials__button socials__button--twitter">twitter</span>*/}
          {/*  <span className="socials__button socials__button--youtube">youtube</span>*/}
          {/*</div>*/}
        </div>
      </div>

      <div className={`slide ${getClassName(2)}`}>
        <div className="text-block extra-margin--1">
          <h2 className="title" style={{ marginBottom: '40px' }}>
            Unique user experience
            <span className="highlight-title" style={{ marginTop: '20px' }}>One nickname</span>
            <span className="highlight-title" style={{ marginTop: '12px' }}>One friendlist</span>
            <span className="highlight-title" style={{ marginTop: '12px', marginBottom: '20px' }}>One wallet</span>
            Across all communities
          </h2>
          {/*<div className="stats">*/}
          {/*  <div className="stat">*/}
          {/*    <span className="stat-value">{'>10'}</span>*/}
          {/*    <span className="stat-label">services</span>*/}
          {/*  </div>*/}
          {/*  <div className="stat">*/}
          {/*    <span className="stat-value">{'>200 000'}</span>*/}
          {/*    <span className="stat-label">users</span>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<p className="description">*/}
          {/*  Emails us to join a fast growing global community.*/}
          {/*</p>*/}
          <a className="secondary-button" href="https://passport.maji.la/auth/registration">
            Reserve your Nickname
          </a>
        </div>
      </div>

      <div className={`slide ${getClassName(3)}`}>
        <div className="text-block extra-margin--2">
          <h2 className="title">
            Revenue
            <span className="highlight-title">multiplication</span>
          </h2>
        </div>
        <ul className="list-items" style={{ marginBottom: '40px' }}>
          <p>The MAJI network enables artists and creators to</p>
          <li>Easily share content.</li>
          <li>Track revenue and contributions.</li>
          <li>Share user data across multiple communities.</li>
        </ul>
        {/*<div className="partner">*/}
        {/*  <h1 className="partner__title designed">Try it out on</h1>*/}
        {/*  <div className="partner__list">*/}
        {/*    <img src={argoLogo} alt="Argo"/>*/}
        {/*    <img src={brandonLogo} alt="Brandon TV"/>*/}
        {/*    <img src={supafanatixLogo} alt="Supafanatix"/>*/}
        {/*    <img src={aviSnowLogo} alt="Avi Snow"/>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="text-block">
          <a className="primary-button"
             href={`mailto:${CONFIG.supportEmail}?subject=Join a fast growing global community`}>
            Join the Community
          </a>
        </div>
      </div>
    </>
  );
}
