import React, { useCallback } from 'react';

export const Navigation = (props: {
  activeIndex?: number;
  setActiveIndex: (index: number) => void;
}) => {
  const { activeIndex, setActiveIndex } = props;

  const selectIndex = useCallback((index: number) => {
    if (activeIndex !== index) {
      setActiveIndex(index);
    }
  }, [activeIndex, setActiveIndex]);

  return (
    <div className="navigation">
      <div className="line"></div>

      <div
        className={`ellipse ${activeIndex === 0 ? 'active' : ''}`}
        onClick={() => selectIndex(0)}
      ></div>
      <div
        className={`ellipse ${activeIndex === 1 ? 'active' : ''}`}
        onClick={() => selectIndex(1)}
      ></div>
      <div
        className={`ellipse ${activeIndex === 2 ? 'active' : ''}`}
        onClick={() => selectIndex(2)}
      ></div>
      <div
        className={`ellipse ${activeIndex === 3 ? 'active' : ''}`}
        onClick={() => selectIndex(3)}
      ></div>
    </div>
  );
}
