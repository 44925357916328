import { wrapPage } from '../../components/wrapPage/wrapPage';
import React from 'react';
import { Helmet } from 'react-helmet';
import { CONFIG } from '../../../config';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../core/routes';

const PrivacyPage = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content={`${CONFIG.companyName} Privacy Policy`}/>
        <title>MAJI Privacy Policy</title>
      </Helmet>

      <div className="content privacy">
        <Link to={ROUTES.main} className="back-link"></Link>

        <div className="content__column terms-card">
          <h1 className="terms-card__title">
            Privacy Policy
          </h1>
          <Scrollbars className="terms-card__text">
            <p>Last Updated: March 20, 2024</p>
            <p>MAJI knows that you care how information about you is used and shared, and we appreciate your trust that
              we will do so carefully and sensibly. This notice describes our privacy policy and how we use your
              personal information when you use passport.maji.la (our “Site”). In this Privacy Notice we explain who we
              are, why and how we collect, store and use (collectively, “process” or “processing”) your personal
              information (also referred to as personal data), your rights and how to contact us.</p>
            <p>We are the data controller - which means we decide how and why we use your personal information as set
              forth in this Privacy Notice.</p>
            <p>By using the service of MAJI Passport, you acknowledge that you have read and understood this privacy
              policy and are accepting the practices described in this Privacy Policy Notice (“Privacy Notice”).</p>

            <h2>This Privacy Policy</h2>
            <p>This Privacy Policy applies to the registration, each access or actual use of any MAJI Service and/or its
              tool, whether currently existing or that which will become available in the future, the terms of use of
              which refer to the MAJI Passport User Agreement located at <Link
                to={ROUTES.terms}>{CONFIG.domen + ROUTES.terms}</Link>.
              By providing the accesses, features and benefits of MAJI, we, acting reasonably and in good faith, believe
              that you:</p>
            <ul>
              <li>have all necessary rights to enroll in MAJI Passport and use MAJI Services and/or its tools;</li>
              <li>provide accurate information about yourself to the extent necessary to use the MAJI Services and/or
                its tools;
              </li>
              <li>know and accept this Privacy Policy.</li>
            </ul>
            <p>We do not verify the user information we collect from you unless such verification is necessary for us to
              fulfill our obligations to you. However, we do assume that you provide true and sufficient information
              about yourself and update it in a timely manner.</p>
            <p>This Privacy Policy applies as in effect at the time of actual use.</p>

            <h2>What Information We Collect and How We Use It</h2>
            <p>To implement the agreement between you and us and to provide you with the access, features and benefits
              of MAJI Passport, we improve MAJI Passport's features, develop and implement new Services and tools, and
              improve the availability of MAJI Passport functionality. To achieve these goals and in accordance with
              applicable law, when you register on <a href="https://passport.maji.la">https://passport.maji.la</a>, or
              any MAJI Service, and when you edit your previously submitted information or submit additional information
              about yourself, we collect, store, accumulate, organize, retrieve, compare, use and enhance your
              information. We may also receive and transfer this data and our automatically processed analysis of this
              data to our affiliates and partners.</p>
            <p>Below we set out in more detail our reasons for collecting and processing, the legal basis for the
              information we collect and/or may collect when you use your MAJI Passport.</p>

            <table>
              <tr>
                <th></th>
                <th><strong>Collected information</strong></th>
                <th><strong>Purpose</strong></th>
                <th><strong>Legal basis</strong></th>
              </tr>
              <tr>
                <td>1</td>
                <td>The data you provide when filling in the MAJI Passport registration form on the website at <a
                  href="https://passport.maji.la">https://passport.maji.la</a>: nickname, email, phone number.
                </td>
                <td>This information is the minimum information necessary to enroll you in MAJI Passport. We use this
                  information to manage and administer MAJI, including providing our MAJI access, features and benefits
                  to you. We use this information so that we can fulfill our obligations to you as part of the
                  functionality of the MAJI system (e.g., where you create a profile of the appropriate type in your
                  MAJI account, request account access restoration in MAJI).
                </td>
                <td>Legitimate interests. Enforcement of the contract between us and you.</td>
              </tr>
              <tr>
                <td>2</td>
                <td>The data you provide when registering for any MAJI Service, including your nickname, email, phone
                  number. Individual Services may ask you for less, and you may be required to complete such incomplete
                  account with the necessary data to complete your registration, or additional information required to
                  register with such Services and MAJI
                </td>
                <td>We use this information to manage and administer the MAJI System, including providing our MAJI
                  access, features and benefits to you. We use this data so that we can fulfill our obligations to you
                  as part of MAJI functionality (for example, when you request to regain access to your MAJI account).
                </td>
                <td>Legitimate interests. Enforcement of the contract between us and you.</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Additional data you provide when you edit your MAJI Passport account</td>
                <td>We use this information to manage and administer the MAJI System, including providing our MAJI
                  access, features and benefits to you. We also use this information to improve the user experience.
                </td>
                <td>Legitimate interests. Enforcement of the contract between us and you.</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Additional data you provide when you use MAJI Services and/or its tools.</td>
                <td>We use this information to manage and administer the MAJI System, including providing our MAJI
                  access, features and benefits to you.
                </td>
                <td>Legitimate interests. Enforcement of the contract between us and you.</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Additional data obtained when accessing the MAJI Services and/or its tools, including information
                  about technical devices, technical interaction with the MAJI Service and/or its tool (host IP address,
                  operating system, browser information, geographic location, time of visit, consent information,
                  internet service provider, as well as your navigation behavior and further actions in the MAJI System.
                </td>
                <td>We use your data for internal controls to continually improve the access, features and benefits of
                  the MAJI System, to understand any errors that may occur when using MAJI and to notify you of changes
                  to MAJI.
                </td>
                <td>Legitimate interests. Enforcement of the contract between us and you.</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Location data</td>
                <td>We use this information for the purpose of improving the user experience.</td>
                <td>Legitimate interests. Enforcement of the contract between us and you.</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Information you create when you make requests to our Support Team</td>
                <td>We use this information to fulfill your request for support. We may also use this data to
                  investigate any complaints on your behalf and provide you with a better service.
                </td>
                <td>Legitimate interests. Enforcement of the contract between us and you.</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Information obtained as a result of your behavioral actions in MAJI (in particular the use of a
                  particular Ecosystem Service or tool), such as the date and time of accessing the Services or tools;
                  information about your activity while using the Services and/or tools
                </td>
                <td>We use this information to manage and administer MAJI, including providing our MAJI access, features
                  and benefits to you. We may also use this data to investigate any complaints on your behalf and to
                  provide you with a better service.
                </td>
                <td>Legitimate interests. Enforcement of the contract between us and you.</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Data collected through third parties such as OAuth providers - e.g. Sign in with Apple, Google,
                  Facebook.
                </td>
                <td>We import this information into your profile to enable you to register or log into your system
                  account through oAuth providers. The information may also be used to synchronize your MAJI account
                  information with such third parties. Information collected from third parties may also be used to
                  restore your access to your MAJI Passport account.
                </td>
                <td>Legitimate interests. Enforcement of the contract between us and you.</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Information that is automatically accepted when you access the Site using cookies.</td>
                <td>We use this information to customize and improve the advertisements presented in the MAJI Services
                  and its tools and to evaluate the effectiveness of those advertisements.
                </td>
                <td>Enforcement of the contract between us and you</td>
              </tr>
            </table>

            <h2>Our legitimate interests include the following:</h2>
            <ol className="list--letter">
              <li>Maintaining and administering the MAJI System;</li>
              <li>Providing you with the accesses, features and benefits of MAJI, including the Services and Tools;</li>
              <li>Improving and personalizing MAJI's accesses, features and benefits for the benefit of all MAJI
                users;
              </li>
              <li>Understanding how you interact with the MAJI Services and Tools;</li>
              <li>Ensuring that your MAJI account is adequately protected;</li>
              <li>Collecting, processing and presenting statistical data, big data and other research;</li>
              <li>Complying with any contractual, legal or regulatory obligations under applicable law.</li>
            </ol>
            <p>Personal Data we collect about you when you use MAJI Services and/or tools may be matched and linked to
              other information we collect about you as part of your use of your MAJI account (e.g., contact
              information, age and gender).</p>
            <p>In some cases, we collect technical data about you from the moment you access the MAJI Service (for
              example, when you download a webpage or launch an application), to the moment you register with the
              relevant Service.</p>
            <p>In all cases, your personal data will be processed solely for the purposes set out in this Privacy
              Policy, unless otherwise set out in the User Documentation of the relevant Service/tool.</p>
            <p>By the functionality and User Documentation of individual Services, you may be given the opportunity to
              post any other information about yourself on the Services other than that specified in accordance with
              this Privacy Policy. We do not intend to process such information and do not include it in the information
              we collect about you.</p>
            <p>Some MAJI Services and tools allow information about you to be reflected in your profile, which is
              available for viewing by other users of such Service or Internet users. Such information, including your
              personal information, may be available to an unspecified number of Internet users, subject to the
              architecture and functionality of the applicable Service or MAJI tool (which may change from time to time)
              and your desired privacy settings. We, acting reasonably and in good faith, believe that you understand
              that by posting your personal information, you have explicitly made that information publicly available,
              and that this information may be made available to, copied and distributed by other users of the Service
              and Internet users. We recommend that you decide responsibly about the amount of information about
              yourself that you post on the Services.</p>
            <p>Your personal information may also be processed if law enforcement or regulatory authorities, bodies or
              agencies lawfully require it, or for protection or legal requirements. We will not delete personal
              information if it is relevant to an investigation or dispute. Information will continue to be retained
              until such matters are fully resolved and/or for as long as is required and/or permissible under
              applicable law.</p>
            <p>Please note that if you withdraw your consent to processing or fail to provide the data we require to
              maintain and administer MAJI, you will not be able to access MAJI Services and its tools or register with
              MAJI and its Services.</p>
            <p>In order to fulfill the agreement between us and to provide you with access, features and benefits of
              MAJI, we develop MAJI, the services, tools and products provided, develop and implement new services,
              tools and products, improve them and optimize their quality. In order to ensure that these goals are
              realized, we may send you service mailings (e.g., emails, push notifications, SMS) to solicit feedback
              through our services, tools and/or through third parties. We may collect, store, store, store, collect
              such data.</p>

            <h2>Our promotional updates and communications</h2>
            <p>Where permitted in our legitimate interests (explained below) or with your prior consent if required by
              law, we may send you marketing and promotional updates about our services, by email, push notifications
              and SMS, if you have opted-in to receiving these.</p>
            <p>We compare our user list to lists received from other companies, in an effort to avoid sending
              unnecessary messages to our users. If you do not want to receive email or other mail from us, please
              adjust your preferences. You can object to further marketing at any time by checking and updating your
              contact details within your account, or selecting the "unsubscribe" link at the end of all our marketing
              and promotional update communications to you, or by sending us an email at <a
                href="mailto:help@maji.la">help@maji.la</a>.</p>

            <h2>Who Do We Share Your Information with</h2>
            <p>We do not share your personal information with any third parties except as expressly permitted by the
              MAJI User Agreement, this Privacy Policy, or the policies of individual MAJI Services.</p>
            <p>We may share your data with MAJI Services, as well as external Services that use MAJI tools to provide
              you with MAJI access, features and benefits, for example, if you choose to log in to a Service using the
              MAJI Passport tool. By starting to use such Service / tool / its individual functions, you consent to such
              transfer of your data to the extent necessary for the operation of the Service / tool. By doing so, you
              also agree to and accept the User Documentation of such MAJI Service and/or its tool in its entirety,
              without qualification or exception. If you do not agree with any of the provisions of the User
              Documentation of a Service/tool, you may not use such Service/tool.</p>
            <p>We may also share your data with our third-party contractors and developers of MAJI Services and its
              tools, provided that these third parties comply with obligations regarding the privacy of your personal
              data collected by MAJI and the Services and tools they offer that you use.</p>
            <p>We sometimes give you the option to share your MAJI account data with third parties through special
              technologies (OAuth, etc.), provided that you consent to such sharing. However, we do not guarantee the
              completeness and correctness of the account data transfer and are not responsible for the safety of the
              data during the transfer process.</p>

            <h2>Where We Store Your Information</h2>
            <p>MAJI MAY CONTRACT WITH A VARIETY OF THIRD PARTY SUPPLIERS, PROVIDERS, VENDORS, AND SOCIAL NETWORKS FROM
              TIME TO TIME, TO PROVIDE FOR OUR HOSTING, AUTHENTICATION, SERVING, STORAGE AND TELECOMMUNICATION NEEDS,
              ETC., INCLUDING WITHOUT LIMITATION STORAGE OF OUR USERS PERSONALLY IDENTIFIABLE INFORMATION. MAJI SHALL
              NOT BE RESPONSIBLE OR LIABLE, AND HEREBY DISCLAIMS ALL RESPONSIBILITY AND LIABILITY, FOR CULPABLE
              (INCLUDING WITHOUT LIMITATION NEGLIGENT) ACTS OR OMISSIONS BY ITS THIRD PARTY SUPPLIERS/VENDORS.</p>
            <p>The data that we collect from you may be transferred to, and stored at, a destination outside the
              European Economic Area ("EEA") that may not be subject to equivalent Data Protection Law.</p>
            <p>Where your information is transferred outside the EEA, we will take all steps reasonably necessary to
              ensure that your data is subject to appropriate safeguards, such as relying on a recognized legal adequacy
              mechanism, and that it is treated securely and in accordance with this privacy policy.</p>
            <p>We may transfer your personal information outside the EEA:</p>
            <ul>
              <li>In order to store it.</li>
              <li>Where we are legally required to do so.</li>
              <li>In order to facilitate the operation of our group of businesses, where it is in our legitimate
                interests and we have concluded these are not overridden by your rights.
              </li>
            </ul>

            <h2>How We Protect Your Information</h2>
            <p>Unfortunately, the transmission of information via the internet is not completely secure, and while we do
              our best to protect your personal information, we cannot guarantee the security of data transmitted to our
              Site; any transmissions are at your own risk.</p>
            <p>MAJI operates or leases secure data networks in the United States protected by industry standard firewall
              and password protection systems. Our security and privacy policies are periodically reviewed and enhanced
              as necessary, and only authorized individuals have access to the information provided by our Clients.
              Notwithstanding such measures, we cannot guarantee that our security measures will prevent our computers
              from being illegally accessed, and the individual information on them stolen or altered.</p>
            <p>Your account is protected by a password for your privacy and security. You must prevent unauthorized
              access to your account and Personal Information by selecting and protecting your password appropriately
              and limiting access to your computer or device and browser by signing off after you have finished
              accessing your account.</p>
            <p>Our site may, from time to time, contain links to external sites. We are not responsible for the privacy
              policies or the content of such sites.</p>

            <h2>How Long We Keep Your Information</h2>
            <p>We retain personal data you provide to us for as long as necessary in order to meet our legal obligations
              to you, and for six years after that to identify any issues and resolve any legal proceedings. We may also
              retain aggregate information beyond this time for research purposes and to help us develop and improve our
              services. You cannot be identified from aggregate information retained or used for these purposes.</p>
            <p>We will retain personal data collected from you through the use of cookies and related technologies for
              the duration of your browsing session on the site.</p>

            <h2>Your Rights</h2>
            <p>You have the right under certain circumstances:</p>
            <ul>
              <li>to be provided with a copy of your personal information;</li>
              <li>to request the correction or deletion of your personal information (you can delete your account after
                signing in to Site);
              </li>
              <li>to request that the restriction the processing of your personal information (while your concerns are
                investigated or verified, for example);
              </li>
              <li>to object to the further processing of your personal information; and</li>
              <li>to request that your provided personal information be moved to a third party.</li>
            </ul>
            <p>You may independently disable individual Services through your MAJI account. By doing so, you waive the
              use of such Services and all rights previously granted to you in connection with the use of such
              Services.</p>
            <p>In particular, if the processing of your personal information is based on consent, you have the right to
              withdraw that consent without detriment at any time. You can exercise the rights above at any time by
              contacting us at <a href="mailto:help@maji.la">help@maji.la</a>.</p>
            <p>If your request or concern is not satisfactorily resolved by us, you may approach your local data
              protection authority, (see <a target="_blank" rel="noreferrer"
                                            href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm</a>).
              The Information Commissioner is the supervisory authority in the UK and can provide further information
              about your rights and our obligations in relation to your personal data.</p>

            <h2>Changes to This Policy</h2>
            <p>We may change and/or update this Privacy Policy from time to time. If this Privacy Policy changes, we
              will post the updated version on this page. We encourage you to review this page regularly so that you are
              kept up to date with our information practices and any changes to these practices.</p>
            <p>Any changes that we make to this privacy policy in the future will be posted on this page. If you choose
              to visit MAJI, your visit and any dispute over privacy is subject to this Notice and our Terms and
              Conditions, including limitations on damages, resolution of disputes, and application of the law of the
              state of California. If you have any concern about privacy, please contact us with a thorough description,
              and we will try to resolve it. Our business changes constantly, and our Privacy Notice and Conditions of
              Use will change also. We may e-mail periodic reminders of our notices and conditions, but you should check
              our website and/or mobile app frequently to see recent changes.</p>
            <p>Unless stated otherwise, our current Privacy Notice applies to all information that we have about you and
              your account. We stand behind the promises we make, however, and will never materially change our policies
              and practices to make them less protective of user information collected in the past without the consent
              of affected users.</p>
            <p>You have the right to refuse to accept changes and amendments to this Privacy Policy. Such an opt-out
              will constitute your refusal to use all MAJI Services and its tools and all rights previously granted to
              you. If you do not agree to the terms of this Privacy Policy, your use of the MAJI Services and its tools
              must cease immediately.</p>
            <p>You also have the right to refuse to accept changes and amendments to the Policies of individual
              Services. In case of such disagreement, the use of the Service shall be immediately terminated.</p>

            <h2>Are Children Allowed to Use MAJI?</h2>
            <p>MAJI provides a service for adults. If you are under 18, you may use MAJI only with the involvement of a
              parent or guardian. We do not knowingly collect personal information from children under the age of 13
              without the consent of the child's parent or guardian.</p>

            <h2>Contact Us</h2>
            <p>Comments, request, questions regarding this privacy policy can be addressed to: 9330 Beverly Crest Dr,
              Beverly Hills, CA 90210 CA USA</p>
            <p>You can also email us to: <a href="mailto:help@maji.la">help@maji.la</a></p>
            <p>MAJI’s use and transfer of information received from Google APIs to any other app will adhere to <a
              href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noreferrer">Google
              API Services User Data Policy</a>, including the Limited Use requirements.</p>

          </Scrollbars>
        </div>
      </div>
    </>
  );
}

export default wrapPage(PrivacyPage);
