import { wrapPage } from '../../components/wrapPage/wrapPage';
import React from 'react';
import { Helmet } from 'react-helmet';
import { CONFIG } from '../../../config';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../core/routes';

const TermsPage = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content={`${CONFIG.companyName} Terms and Conditions`}/>
        <title>Terms and Conditions</title>
       </Helmet>

      <div className="content terms">
        <Link to={ROUTES.main} className="back-link"></Link>

        <div className="content__column terms-card">
          <h1 className="terms-card__title">
            Terms and Conditions
          </h1>
          <Scrollbars className="terms-card__text">
            <p>Last Updated: March 20, 2024</p>
            <h2>General Provisions</h2>
            <p>
              The Terms and Conditions govern the relationship between the User (you), MAJI and its affiliates arising
              from the provision by MAJI of the verification option, as well as the completion of information about the
              User and/or the simplified registration and/or authorization of the User on the Website and in MAJI's
              services (MAJI Passport) at the User's initiative.
            </p>
            <p>
              This Agreement may be amended and/or supplemented unilaterally by the Company. This Agreement is an open
              and publicly available document. The Company recommends that Users regularly check the terms of this
              Agreement for changes and/or amendments. Continued use of MAJI Passport services and/or MAJI Passport
              tools by the User after changes and/or additions to this Agreement constitutes the User's acceptance and
              consent to such changes and/or additions.
            </p>
            <p>
              The relationship between the parties is also governed by the Privacy Policy, available at <Link
              to={ROUTES.privacy}>{CONFIG.domen + ROUTES.privacy}</Link> and
              the user documentation of the respective services for which the user registers with MAJI Passport.
            </p>
            <p>
              MAJI Passport and/or its affiliates ("MAJI") provide digital content and features to you when you visit or
              use MAJI services, use MAJI applications for mobile, or use software provided by MAJI in connection with
              any of the foregoing (collectively, "MAJI Services"). MAJI provides the MAJI Services subject to the
              following conditions.
            </p>

            <h2>Subject of Agreement</h2>
            <p>
              The purpose of MAJI Passport is to make it possible to fill in information about the User on the User's
              own initiative and to facilitate the User's registration and/or authorization on the Website and in the
              MAJI Services.
            </p>
            <p>
              MAJI Passport shall also be used to create a single User Account, allowing the User to securely manage the
              data of the personal Account and to access the various MAJI Services through a single Account on the
              Website.
            </p>
            <p>
              MAJI Passport shall receive and centrally store information provided by the User, including the User's
              personal data, including the possibility to subsequently transfer such information to Affiliates for the
              purposes of these Terms and Conditions as well as the rules of use of the relevant MAJI Services.
            </p>
            <p>
              With MAJI Passport, the User is given the following options:
            </p>
            <ol>
              <li>create an editable User Account;</li>
              <li>fill in information about themselves on their own initiative, as well as perform simplified
                registration and/or authorization on the Website and in MAJI Services based on the User's data stored in
                the Personal Account;
              </li>
              <li>securely manage their data through a single User Account, including adding, modifying or deleting such
                data at will, except as otherwise provided in these Terms and Conditions of the applicable MAJI
                Services.
              </li>
            </ol>
            <p>
              Within MAJI Passport, MAJI shall transfer data from User Account to the various MAJI Services and, where
              provided for in the user interface of the relevant MAJI Services or their Terms and Conditions, vice
              versa, from the MAJI Services to User Account.
            </p>

            <h2>Creating a MAJI Passport account</h2>
            <p>
              In order to obtain the functional capabilities of MAJI Services, the User shall create an account in MAJI
              Passport.
            </p>
            <p>
              Such an account is created when registering on MAJI Passport website (<a
              href="https://passport.maji.la/">https://passport.maji.la/</a>), or when registering in any MAJI Service,
              or when registering in an external Service using the MAJI Passport tool.
            </p>
            <p>
              Creation of an account in MAJI Passport is free of charge and is performed by filling in the MAJI Passport
              registration form on the website or through MAJI Services, or through an external Service using MAJI tool,
              including by transferring necessary information from the Service to MAJI, including in the process of
              registration and/or authorization in the Service.
            </p>
            <p>
              The User agrees to provide true and complete information necessary to create and/or operate the Account
              and use MAJI Passport, and is solely responsible for the accuracy, completeness, reliability and
              timeliness of such information. MAJI and its affiliates assume no obligation to verify the accuracy,
              completeness, reliability and relevance of the data provided.
            </p>
            <p>
              The User may also specify, change or delete his/her nickname, e-mail address, phone number and other data
              in the Account if provided for by the MAJI Passport user interface. The ability to change or delete the
              User's data may be limited by these Terms and Conditions and/or the Rules of individual MAJI Services.
            </p>

            <h2>Conditions of Use</h2>
            <p>
              The user can authorize in the Account when the MAJI Passport user interface provides the appropriate
              method:
            </p>
            <ul>
              <li>by providing an email address and creating a password;</li>
              <li>by using Facebook ID;</li>
              <li>by using Apple ID;</li>
              <li>by using an existing Google Account.</li>
            </ul>
            <p>
              The User agrees that they and/or their Account may be subject to any restrictions (including blocks)
              related to security or the violation of these Terms by the User, as well as for technical reasons. In
              addition, by accepting these Terms, the User agrees that restrictions may be imposed on the User and/or
              their Account at any time without cause and without notice. The restrictions specified in this clause
              shall survive any termination of these Terms with the User, regardless of the grounds.
            </p>
            <p>
              To delete an Account, the User should click on the "Delete Account" button in the account settings and
              follow the instructions. The possibility to delete the Account may be limited by these Terms and
              Conditions and/or the rules of use of individual MAJI Services.
            </p>
            <p>
              Deletion of the Account means termination of these Terms and Conditions by the User. In case the User
              requests MAJI and/or Affiliates to stop processing their personal data, such request is also a request of
              the User to terminate these Terms and Conditions and results in deletion of the Account.
            </p>
            <p>
              Termination of the Terms and deletion of the Account shall take up to 30 (thirty) calendar days.
            </p>
            <p>
              To ensure normal operation of MAJI Services, including to provide the User with appropriate functionality
              (e.g. correct display of web pages, operation of data input/output), such MAJI Services may collect and
              use certain technical information about User devices (e.g. screen resolution, type of operating system).
            </p>
            <p>
              Resetting of said settings, elections and technical information can be performed by the User by accessing
              the browser settings (via clearing cache / deleting cookies).
            </p>

            <h2>Personal data</h2>
            <p>
              MAJI and Affiliates process personal data provided by the User to fulfill these Terms and Conditions, as
              well as the rules of use of the relevant MAJI Services.
            </p>
            <p>
              When processing personal data, MAJI and Affiliates shall ensure confidentiality of User's personal data,
              as well as take necessary legal, organizational and technical measures to protect personal data received
              from User from unlawful or accidental access to it, destruction, modification, blocking, copying,
              provision, distribution of personal data, as well as from other unlawful actions in relation to personal
              data received from User.
            </p>

            <h2>Digital content</h2>
            <p>
              <ol type="a">
                <li>General. MAJI may allow you to support a creator to (i) access Digital Content on a subscription basis for viewing during a limited period of time during a subscription period ("Subscription Digital Content"), (ii) rent Digital Content for on-demand viewing over a limited period of time ("Rental Digital Content"). You pay on a one-time or automatically-renewing subscription basis as specified; Digital Content may be available as Subscription Digital Content, Rental Digital Content, Exclusive Creations or Merchandise, or any combination thereof, and in each case is subject to the limited license granted below.</li>
                <li>Subscriptions/Memberships. The timing and amount of each subscription or offering depends on the type of subscription or offering you select and the creator you support. You can see the details prior to purchasing the offering or starting the subscription. Offers and pricing for subscriptions (also referred to at times as memberships), the subscription services, the extent of available Subscription Digital Content, and the specific titles available through subscription services, may change over time and by location without notice (except as may be required by applicable law). Unless otherwise indicated, any price changes are effective as of the beginning of the next subscription period. If you do not agree to a subscription change, you may cancel your subscription per Section 4(c) below. The pricing for subscriptions or memberships may include sales tax, VAT and/or other taxes. Where applicable, such taxes will be collected by the party with whom you transact for the service, which may be MAJI or a third party. We make no guarantee as to the availability of specific Subscription Digital Content or the minimum amount of Subscription Digital Content available in any subscription.</li>
                <li>
                  Cancellation of Subscriptions/Memberships You may cancel a subscription or membership service any time
                  by visiting Your Account and adjusting your membership settings, or by contacting MAJI customer
                  service
                  at <a href="mailto:help@maji.la">help@maji.la</a>. For customers in the European Union, if you cancel within 14 days of receiving confirmation of your subscription or membership service, we will refund your full membership fee; except that we may charge you (or withhold from your refund) the value of the MAJI service used through your account during such period. You also expressly accept that the MAJI service will start within your cancellation period. If you cancel at any other time, we will refund your full membership fee only if Digital Content available as part of your membership has not been accessed through your account since your latest membership charge.
                </li>
                <li>Payment Methods. If we are unable to process your payment using your designated payment method, we reserve the right to charge any payment method we have on file for you. If you purchase a subscription, your subscription will automatically continue, and you authorize us (without further notice, unless required by applicable law) to collect the then-applicable periodic subscription fee including any taxes, using any payment method we have on file for you. We will continue to bill you for the relevant subscription service until canceled. If all payment methods we have on file for you are declined for payment of your subscription fee, your subscription will be canceled unless you provide us with a new payment method. If you provide us with a new payment method and are successfully charged before your subscription is canceled, your new subscription period will be based on the original billing date and not the date of the successful charge. You can use "Your Account" settings to update your designated payment method(s).</li>
                <li>
                  Limited License to Digital Content. Subject to payment of any charges to access Digital Content, and
                  your compliance with all terms of this Agreement, MAJI grants you a non-exclusive, non-transferable,
                  non-sublicensable, limited license, during the applicable Viewing Period, to access and view the
                  Digital Content for personal, non-commercial, private use. We may automatically remove Digital Content
                  from your Compatible Device after the end of its Viewing Period.
                  <br/><br/>
                  MAJI may remove your access to the Digital Content at any time, if the content is being screen
                  recorded through devices registered to your account.
                  <br/><br/>
                  MAJI may remove your access to the Digital Content at any time, if your profile is being shared between more than 3 devices or watched simultaneously in different locations.
                </li>
                <li>
                  General Restrictions. You may not (i) transfer, copy or display the Digital Content, except as permitted in this Agreement; (ii) sell, rent, lease, distribute, or broadcast any right to the Digital Content; (iii) remove any proprietary notices or labels on the Digital Content; (iv) attempt to disable, bypass, modify, defeat, or otherwise circumvent any digital rights management or other content protection system used as part of the Service; or (v) use the MAJI service or Digital Content for any commercial or illegal purpose.
                </li>
                <li>
                  Refunds. Our policy is not to provide refunds, including if you lose access to offerings and/or membership subscription benefits as described above, though we may allow for some exceptions where refunds are granted at our sole discretion.
                </li>
              </ol>
            </p>

            <h2>Additional Terms</h2>
            <h3>Reservation of Rights; Waiver</h3>
            <p>
              The Service, Software and the Digital Content embody intellectual property that is protected by law. All
              text information and graphics on this Website are owned by MAJI. All text information and graphics in the
              MAJI Services are owned by MAJI or the relevant Affiliates. Copyright owners of Digital Content are
              intended third-party beneficiaries under the Agreement. Our failure to insist upon or enforce your strict
              compliance with the Agreement will not constitute a waiver of any of our rights.
            </p>

            <h3>Communications</h3>
            <p>
              We may send you promotions or otherwise communicate with you electronically, which may include e-mail or
              push notification and you hereby consent to receive those communications (unless you are a customer in the
              European Union, in which case the preceding sentence does not apply). These communications will be in
              accordance with the MAJI Privacy Policy Notice. To stop receiving marketing communications from MAJI,
              update your marketing communication preferences from Your Account.
            </p>

            <h3>Modification of Service</h3>
            <p>
              MAJI reserves the right to modify, suspend, or discontinue the Service, or any part of the Service, at any
              time and without notice (except as required by applicable law), and MAJI will not be liable to you should
              it exercise such rights.
            </p>

            <h3>Amendments</h3>
            <p>
              MAJI reserves the right to make changes to this Agreement at any time by posting the revised terms in
              connection with the Service. To the maximum extent permitted by law, your continued use of the Service or
              Software following any changes will constitute your acceptance of such changes.
            </p>

            <h3>Applicable Law</h3>
            <p>
              By using any Service, you agree that the Federal Arbitration Act, applicable federal law, and the laws of
              the state of California, without regard to principles of conflict of laws, will govern these Conditions of
              Use and any dispute of any sort that might arise between you and MAJI.
            </p>

            <h3>Disputes</h3>
            <p>
              Any dispute or claim arising from or relating to this Agreement or the Service is subject to the governing
              law, disclaimer of warranties and limitation of liability, any binding arbitration, and all other terms in
              the MAJI Conditions of Use. You agree to those terms by using the Service. YOU MAY ALSO BE ENTITLED TO
              CERTAIN CONSUMER PROTECTION RIGHTS UNDER THE LAWS OF YOUR LOCAL JURISDICTION.
            </p>
            <p><strong>
              CERTAIN JURISDICTIONS, INCLUDING JURISDICTIONS IN THE EUROPEAN UNION, DO NOT ALLOW THE EXCLUSION OF
              IMPLIED WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES. IF THESE LAWS
              APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND
              YOU MAY HAVE ADDITIONAL RIGHTS.
            </strong></p>

            <h3>Contact Information</h3>
            <p>
              For communications concerning this Agreement, please write to MAJI at: 9330 Beverly Crest Dr, Beverly
              Hills, CA 90210 CA USA; or e-mail to: <a href="mailto:help@maji.la">help@maji.la</a>
            </p>

            <h3>Severability</h3>
            <p>
              If any term or condition of this Agreement is deemed invalid, void, or for any reason unenforceable, that
              part will be deemed severable and will not affect the validity and enforceability of any remaining term or
              condition.
            </p>
          </Scrollbars>
        </div>
      </div>
    </>
  );
}

export default wrapPage(TermsPage);
