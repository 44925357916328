import React, { useEffect, useRef } from 'react';
import { Footer } from '../footer';
import { CONFIG } from '../../../config';
import backgroundPoster from '../../../assets/img/bg.jpg';

type WrapPageProps = {
  wrapperClassName: string,
}

export function wrapPage(WrappedComponent: any) {
  return ({
    wrapperClassName,
  }: WrapPageProps) => {
    const bgVideo = CONFIG.VIDEO_URL_DESKTOP;
    const videoRef = useRef<HTMLVideoElement>(null);
    const bgPoster = backgroundPoster;

    useEffect(() => {
      const isPreRender = navigator.userAgent === "ReactSnap";

      if (isPreRender) {
        return;
      }

      if (videoRef) {
        videoRef.current?.play();
      }
    }, [videoRef])

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return (
      <div className={'wrap-page ' + wrapperClassName}>
        <div className="videoBG">
          <video ref={videoRef}
                 className="videoElement"
                 poster={bgPoster}
                 preload="auto"
                 muted={true}
                 loop={true}
                 playsInline={true}
          >
            <source src={bgVideo} type="video/mp4"/>
          </video>
        </div>

        <WrappedComponent/>

        <Footer/>
      </div>
    )
  }
}
