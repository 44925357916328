import React from 'react';
import { wrapPage } from '../components/wrapPage/wrapPage';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { ROUTES } from '../core/routes';

const ErrorPage = () => {
  return (
    <>
      <Helmet>
        <title>404 - MAJI.LA</title>
      </Helmet>

      <div className="page-content">
        <Link to={ROUTES.main} className="back-link"></Link>

        <div className="page-content__item error-page-content">
          <h1 className="title">
            Page Not Found
          </h1>
          <p className="subtitle">
            Sorry, but the page you were trying to view does not exist.
          </p>
        </div>
      </div>
    </>
  );
};

export default wrapPage(ErrorPage);
